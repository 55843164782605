.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin 1 200ms linear;
  }
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #000;
}

.App-link {
  color: #000;
  transition: all 200ms ease-in;
  margin-top: 2px;
  margin-bottom: 2px;
}
.App-link b{
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.2rem;
}
.App-link:hover{
  transform: scale(1.1);
}

.App-home-row{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.App-social-link{
  margin: 5px;
}
.App-social-link img{
  width: 30px;
  height: 30px;
}

@keyframes App-logo-spin {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
